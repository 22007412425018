<template>
  <div class="input">
    <!--    详细信息弹框 开始-->
    <el-dialog title="订单详情" :visible.sync="dialogDetails" :close-on-click-modal="false" width="650px" class="screen">
      <div v-loading="loading">
        <el-form inline :label-width="screenLabelWidth">
          <el-row>
            <el-form-item label="入住客房" >
              <!-- <el-input v-model="params.address" disabled size="mini" class="screenInput"></el-input> -->
                <el-tooltip class="item" effect="dark" :content="params.address" placement="top">
                  <el-input v-model="params.address" disabled size="mini" class="screenInput"></el-input>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="客房类型">
              <el-input v-model="params.hsRoomType" disabled size="mini" class="screenInput"></el-input>                      
            </el-form-item>
            <el-form-item label="客户姓名">
              <el-input v-model="params.popName" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="日均单价">
              <el-input v-model="params.jsrcDailyPrice" disabled size="mini" class="screenInput"></el-input>            
            </el-form-item>
            <el-form-item label="总住天数">
              <el-input v-model="params.jsrcTotalDays" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="总住金额">
              <el-input v-model="params.jsrcTotalPrice" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row v-for="(value,i) in params.popTmpList" :key="i">
            <el-form-item label="住户姓名">
              <el-input v-model="value.popName" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="住户手机">
                <el-input v-model="value.popTelephone" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="住户身份">   
              <el-tooltip class="item" effect="dark" :content="value.popIdcard" placement="top">         
              <el-input v-model="value.popIdcard" disabled size="mini" class="screenInput"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="客房押金">
                <el-input v-model="params.jsrcDeposit" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="入住时间">
              <el-input v-model="params.jsrcBeginTime" disabled size="mini" class="screenInput"></el-input>             
            </el-form-item>
            <el-form-item label="退房时间">
              <el-input v-model="params.jsrcEndTime" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="订单状态">
                <el-input v-model="params.jsrcState" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="入住类型">
              <el-input v-model="params.jsrcTypeOccupancy" disabled size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 15px"><!-- 消费记录 -->
              <el-button @click="consumption.dialogConsumption = true" size="mini" v-if="authe.Hotel_Core_OrderTrack_ExpensesRecord">消费记录</el-button>
            </el-form-item>
          </el-row>
        </el-form>
        <el-table
        :data="followUp.dataFollowUp"
        highlight-current-row
        @current-change="followUpCurrentChange"
        @row-dblclick="followUp.dialogFollowUpDetails = true"
        height="216px"
        size="mini"
        style="width:100%;" >
          <el-table-column prop="registrantName" label="操作人" min-width="25px"></el-table-column>
          <el-table-column prop="type" label="类型" width="80px"></el-table-column>
          <el-table-column prop="text" show-overflow-tooltip label="操作记录" min-width="100px"></el-table-column>
          <el-table-column prop="time" label="操作时间" width="150px"></el-table-column>
        </el-table>
        <div slot="footer" style="text-align: center">
          <el-button @click="nextOrPrev(false)">上一条</el-button>
          <el-button type="primary" v-if="(params.jsrcState == '预定' && params.jsrcOrderState == 0) && authe.Hotel_Core_OrderTrack_Orders" @click="acceptOrder" icon="el-icon-takeaway-box">接 单</el-button>
          <el-button type="primary" v-if="params.jsrcState == '预定' && authe.Hotel_Core_OrderTrack_Chargeback" @click="retreatOrder" icon="el-icon-takeaway-box">退 单</el-button>
          <el-button type="primary" v-if="(params.jsrcState == '已住' || params.jsrcState == '退房') && authe.Hotel_Core_OrderTrack_PrintingTickets" @click="printedHistory(params.jsrcState)" icon="el-icon-printer">打印票据</el-button>
          <el-button @click="dialogDetails = false">取 消</el-button>
          <el-button @click="nextOrPrev(true)">下一条</el-button>
        </div>
      </div>
    </el-dialog>
    <!--    详细信息弹框 结束-->

    <!--    消费记录详情弹窗 开始-->
    <el-dialog title="消费记录详情" :visible.sync="consumption.dialogConsumption" append-to-body :close-on-click-modal="false" width="460px" class="screen">
      <el-form inline :label-width="screenLabelWidth">
        <el-form-item label="总金额">
          <el-input v-model="consumption.amount" disabled size="mini" class="screenInput"></el-input>
        </el-form-item>
      </el-form>
      <el-table
      :data="consumption.dataConsumption"
      highlight-current-row
      @row-dblclick="followUp.dialogFollowUpDetails = true"
      height="216px"
      size="mini"
      style="width:100%;" >
        <el-table-column prop="popservice" label="服务类型" min-width="100px"></el-table-column>
        <el-table-column prop="popcharge" label="服务金额" min-width="100px"></el-table-column>
      </el-table>
    </el-dialog>
    <!--    消费记录详情弹窗 结束-->

    <!--    跟进详细信息弹窗 开始-->
    <el-dialog title="跟进详细信息" :visible.sync="followUp.dialogFollowUpDetails" append-to-body :close-on-click-modal="false" width="460px" class="screen">
      <el-form inline :label-width="screenLabelWidth">
        <el-row>
          <el-form-item label="操作时间">
            <el-input v-model="followUp.rowData.time" disabled size="mini" style="width:335px"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="操作人">
            <el-input v-model="followUp.rowData.registrantName" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-input v-model="followUp.rowData.type" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="操作记录">
            <el-input v-model="followUp.rowData.text" disabled type="textarea" rows="4" size="mini" style="width:335px"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
    <!--    跟进详细信息弹窗 结束-->

    <!--    打印票据弹窗 开始-->
    <el-dialog title="打印票据" :visible.sync="printed.dialogprintedHistory" append-to-body :close-on-click-modal="false" width="460px"  class="screen print" @close="printed.ticketInformation = {}">
      <div id="print" style="width: 99%; height:99%;">
        <div id="body" style="width:250px;margin: 0 auto" >
          <div class="other_fee" style="heigth: 30%">
            <div class="title" >
              <div v-if="printed.showState" style="font-size: 22px;text-align: center;font-size: 700">{{printed.ticketInformation.jsrsuWxgzhTitle}}<br />入住单</div>
              <div v-else style="font-size: 20px;text-align: center;font-size: 700">{{printed.ticketInformation.jsrsuWxgzhTitle}}<br />退房单</div>
              <table style="width: 200px;table-layout:fixed; font-size: 14px;border-bottom: 1px solid #DADADA !important">
                <tbody style="font-weight: 400;">
                <tr>
                  <td style="width: 250px " >订单号/No {{printed.ticketInformation.saleNo}}</td>
                </tr>
                <tr>
                  <td style="width: 250px " >入住/Arrival {{printed.ticketInformation.arrival}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">客人/Customer {{printed.ticketInformation.customer}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">房号/RoomNo {{printed.ticketInformation.roomNo}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">预离/Leave {{printed.ticketInformation.leave}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="other_fee" v-if="printed.showState" style="border-bottom: 1px solid #DADADA !important">
            <div class="paymentVoucher">
            <h2 style="font-size: 14px;text-align: center;">付款信息/PaymentVoucher</h2>
            <table style="width: 100%;font-size:14px;">
              <tbody  style="font-weight: 400">
              <tr>
                <td style="width: 250px ">房价/Price ￥{{printed.ticketInformation.price}}</td>
              </tr>
              <tr>
                <td style="width: 250px ">押金/Deposit ￥{{printed.ticketInformation.deposit}}</td>
              </tr>
              <tr>
                <td style="width: 250px ">总金额/TotalSum ￥{{printed.ticketInformation.totalSum}}</td>
              </tr>
              <tr>
                <td style="width: 250px ">付款方式/PayMthod {{printed.ticketInformation.paymentMthod}}</td>
              </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div class="other_fee" v-else>
            <div class="paymentVoucher">
            <h2 style="font-size: 14px;text-align: center;" v-if="printed.ticketInformation.paymentMthod == '现金退款' ||printed.ticketInformation.paymentMthod == '微信退款'">退款信息/RefundInformation</h2>
            <h2 style="font-size: 14px;text-align: center;" v-else>缴费信息/PaymentInformation</h2>
            <table style="width: 100%; border-bottom: 1px solid #DADADA;font-size:14px" v-if="printed.ticketInformation.paymentMthod == '现金退款' || printed.ticketInformation.paymentMthod == '微信退款'">
              <tbody style="font-weight: 400">
                <tr>
                  <td style="width: 250px ">总金额/TotalSum ￥{{printed.ticketInformation.totalSum}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">支付方式/PayMthod  {{printed.ticketInformation.paymentMthod}}</td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%;border-bottom: 1px solid #DADADA;font-size:14px" v-else>
              <tbody  style="font-weight: 400">
                <tr>
                  <td style="width: 250px ">应收/Receivables ￥{{printed.ticketInformation.totalSum}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">实收/ActualReceipts ￥{{printed.ticketInformation.moneyInput}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">找零/GiveChange ￥{{printed.ticketInformation.moneyInput - printed.ticketInformation.totalSum}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">支付方式/PayMthod  {{printed.ticketInformation.paymentMthod}}</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div>
            <div class="guestInstructions" v-if="printed.showState">
              <h2 style="font-size: 14px;text-align: center;">宾客须知/GuestInstructions</h2>
            </div>
            <div style="font-size:14px;font-weight: 400" v-if="printed.showState">
              1. 每日最后退房时间为中午{{printed.ticketInformation.setUpCheckOutTime}},如超过{{printed.ticketInformation.setUpCheckOutTime}}时，须加收超钟房费。
            </div>
            <div style="font-size:14px;padding-top:5px;font-weight: 400" v-if="printed.showState">
              2.服务电话 {{printed.ticketInformation.telphone}}
            </div>
            <div style="text-align:center;font-weight: 400" >
                <h2>欢迎下次光临</h2>
              <div id="wxgzhImgPath" style="text-align:center;">
                <img width="150px" height="150px" src="../../../assets/hotel-erweima.jpg"/>
              </div>
              </div>
              <div>
              <h2 style="font-size: 14px;text-align: center;">扫码关注微信公众号支持在线定房，让您行程无忧。WeChat scan QR code，Support online booking Let your journey be free from worry。</h2>
            </div>
          </div>
        </div>
      </div>
      <el-row>
        <el-col :span="24" style="text-align: center">
          <el-button type="primary" size="mini" v-print="'#print'">打印</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!--    打印票据弹窗 结束-->
  </div>
</template>
<script>  
import { mapState } from 'vuex';
import moment from 'moment'
export default {
  props: {
    isDialogShow: {
      type: Boolean,
      default: false,
    },
    isDataList: {
      type: Array,
      default() {
        return []
      },
    },
    isRowData: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data(){
    return {
      dialogDetails: this.isDialogShow,
      list: this.isDataList,
      row: this.isRowData,
      loading: false,
      screenLabelWidth: '75px',
      params: {
        address: '',//入住客房
        hsRoomType: '',//客房类型
        popName: '',//客户姓名
        jsrcDailyPrice: '',//日均单价
        jsrcTotalDays: '',//总住天数
        jsrcTotalPrice: '',//总住金额
        popTmpList: '',//同住人信息
        jsrcDeposit: '',//客房押金
        jsrcBeginTime: '',//入住时间
        jsrcEndTime: '',//退房时间
        jsrcState: '',//订单状态
        jsrcTypeOccupancy: '',//入住类型
        jsrcOrderState: '',
      },
      consumption: {
        dialogConsumption: false,
        amount: 0,
        dataConsumption: [],
      },
      followUp: {
        dialogFollowUpDetails: false,
        rowData: {},
        dataFollowUp: [],
      },
      printed: {
        dialogprintedHistory: false,
        showState: true,
        ticketInformation: {}, // 票据信息
      }
    }
  },
  computed: {
    ...mapState(['setupList','userName','authe'])
  },
  created(){
    this.orderDetails()
  },
  watch: {
    dialogDetails(n, o) {
      this.$emit('close');
    },
  },
  methods: {
    orderDetails(){//订单详情
      this.loading = true
      this.$axios({
        method: 'get',
        url: `/hotel/shortRentContract/${this.row.jsrcId}`,
      }).then(res => {
        console.log(res)
        if (res.data.result) {
          let a = res.data.result.hsAddCommunity ? `${res.data.result.hsAddCommunity}\u3000` : ''
          let b = res.data.result.hsRoomType ? `${res.data.result.hsRoomType}\u3000` : ''
          let c = res.data.result.hsAddDoorplateno ? `${res.data.result.hsAddDoorplateno}` : ''
          res.data.result.address = a + b + c
          for (let i in res.data.result) {
            for (let k in this.params) {
              if (i == k) {
                this.params[k] = res.data.result[i]
              }
            }
          }
          if (res.data.result.jsrcAdditionalDescription) {
            this.consumption.dataConsumption = JSON.parse(res.data.result.jsrcAdditionalDescription)
            this.consumption.amount = res.data.result.jsrcAdditionalCost
          } else {
            this.consumption.dataConsumption = []
            this.consumption.amount = 0
          }
          if (res.data.result.jsrcAdditionalDescription) {
            this.followUp.dataFollowUp = JSON.parse(res.data.result.jsrcFollow)
          } else {
            this.followUp.dataFollowUp = []
          }
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    acceptOrder(){//接单
      this.loading = true
      this.$axios({
        method: 'put',
        url: `/hotel/shortRentContract/${this.row.jsrcId}/receipt`,
      }).then(res => {
        console.log(res)
        this.$message.success('接单成功')
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    retreatOrder(){//退单
      this.loading = true
      this.$axios({
        method: 'put',
        url: `/hotel/shortRentContract/${this.row.jsrcId}/cancellation`,
      }).then(res => {
        console.log(res)
        this.$message.success('退单成功')
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    printedHistory(state){//退单打印
      this.loading = true
      let str = ''
      if (state == '已住') {
        str = '租客入住账单'
        this.printed.showState = true // 入住显示的内容
      } else if (state == '退房') {
        str = '租客退房账单'
        this.printed.showState = false // 退房显示的内容
      }
      this.$axios({
        method: 'get',
        url: `/hotel/printedHistory`,
        params: {
          jhpJsrcId: this.row.jsrcId,
          jhpType: str
        }
      }).then(res => {
        this.printed.ticketInformation = res.data.result
        this.printed.dialogprintedHistory = true
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    nextOrPrev(state){
      let data = this.getIndex(this.list,this.row,state,this) //下一条与上一条的处理函数,定义在main.js
      console.log(data)
      if(!data) {
        return
      }
      this.row = data
      this.$emit('nextOrPrev',data);
      this.orderDetails(data)
    },
    followUpCurrentChange(row){//选中行
      this.followUp.rowData = row
    },
  }
}
</script>
<style lang='scss' scoped>
  .screenInput{
    width: 125px;
  }
  .print table{ 
    width: 200px!important; table-layout:fixed!important;
  }
  .print img{
    height:100px!important;width:100px!important
  }
  .print table td {
    border-top: none!important;
    word-wrap:break-word!important;
    font-size: 10px!important;
    font-weight: 400!important;
    width: 250px }
  .print h1{
    font-size: 18px!important;
    text-align:center
  }
  h2{
    font-size: 10px!important;
    text-align:center
  }
  // .print #body{

  // }
  .print #body .other_fee{
    border-bottom: 1px solid #DADADA!important;
  }
</style>
