<template>
  <div v-loading="loading">
    <div ref="screen" class="input">

      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline :label-width="screenLabelWidth" >
          <el-row>
            <el-form-item label="门店名称">
              <el-input v-model="data.params.hsAddCommunity" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="门牌号">
              <el-input v-model="data.params.hsAddDoorplateno" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="客户名称">
              <el-input v-model="data.params.popName" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 10px">
              <el-button type="primary" @click="advancedScreening" size="mini">高级筛选</el-button>
            </el-form-item>
          </el-row>
          <el-row v-show="screenState">
            <el-form-item label="入住类型">
              <el-select v-model="data.params.jsrcTypeOccupancy" filterable @change="searchChange" clearable size="mini" class="screenInput">
                <el-option label="普通客房" value="普通客房"></el-option>
                <el-option label="钟点客房" value="钟点客房"></el-option>
                <el-option label="未入住" value="未入住"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select v-model="data.params.jsrcState" filterable @change="searchChange" clearable size="mini" class="screenInput">
                <el-option label="已住" value="已住"></el-option>
                <el-option label="退房" value="退房"></el-option>
                <el-option label="保留" value="保留"></el-option>
                <el-option label="预定" value="预定"></el-option>
                <el-option label="退定中" value="退定中"></el-option>
                <el-option label="退定" value="退定"></el-option>
                <el-option label="取消保留" value="取消保留"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="入住时间">
              <el-date-picker
                v-model="data.time"
                @change="dateChange"
                :unlink-panels="true"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                filterable
                clearable
                size="mini"
              ></el-date-picker>
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->
      
      <!--    详细信息弹框 开始-->
        <order-detail
          v-if="orderDetailDialog"
          :isDialogShow="orderDetailDialog"
          :isDataList="dataList"
          :isRowData="rowData"
          @nextOrPrev="nextOrPrev"
          @close="orderDetailClose"
        >
        </order-detail>
      <!--    详细信息弹框 结束-->
      
    </div>
    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table
      :data="dataList"
      v-loading="tableLoading"
      ref="highLightRow"
      highlight-current-row
      @current-change="currentChange"
      @row-dblclick="orderDetailDialog = true"
      :height="tableHeight"
      style="width:100%;">
        <el-table-column align="center" prop="hsRoomType" label="房型" min-width="25px"></el-table-column>
        <el-table-column align="center" prop="address" label="地址" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column align="center" prop="popName" label="客户姓名" min-width="30px"></el-table-column>
        <el-table-column align="center" prop="jsrcTotalDays" label="总天数" min-width="25px"></el-table-column>
        <el-table-column align="center" prop="jsrcDailyPrice" label="当天价格" min-width="30px"></el-table-column>
        <el-table-column align="center" prop="jsrcTotalPrice" label="总价" min-width="25px"></el-table-column>
        <el-table-column align="center" prop="jsrcDeposit" label="入住押金" min-width="30px"></el-table-column>
        <el-table-column align="center" prop="jsrcBeginTime" label="入住时间" width="155px"></el-table-column>
        <el-table-column align="center" prop="jsrcEndTime" label="退房时间" width="155px"></el-table-column>
        <el-table-column align="center" prop="jsrcState" label="合约状态" min-width="30px"></el-table-column>
        <el-table-column align="center" prop="suStaffName" label="登记人" min-width="25px"></el-table-column>
        <el-table-column align="center" prop="jsrcRegistrationTime" label="登记时间" width="155px"></el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        :page-count="data.pages"
        :page-size="data.params.limit"
        :total="data.total"
        :current-page="data.params.cursor"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import orderDetail from './components/orderDetail'
export default {
  components: {
    'order-detail': orderDetail
  },
  name: 'order',
  data(){
    return{
      loading: false,
      screenState: false,
      screenLabelWidth: '75px',
      tableHeight: window.innerHeight - 210,
      orderDetailDialog: false,
      data: {//数据分页查询
        params: {
          hsAddCommunity: '',//门店名称
          hsAddDoorplateno: '',//门牌号
          popName: '',//客户名称
          jsrcTypeOccupancy: '',//入住类型
          jsrcState: '',//订单状态
          jsrcBeginTime: '',//入住时间
          jsrcEndTime: '',
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
        time: '',
      },
      dataList: [],
      rowData: {},
      dataCityProper: [],//城区下拉菜单
      dataFloor: [],//楼栋名称下拉菜单

    }
  },
  computed: {
    ...mapState(['authe'])
  },
  created(){
    this.getDataList()
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      
    })
  },
  beforeCreate() {
    console.log(this.orderDetailDialog )
  },
  methods: {
  //查询数据
    advancedScreening(){//高级筛选
      this.screenState ? this.screenState = false : this.screenState = true
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
      })
    },
    getDataList(){//查询数据
      this.tableLoading = true
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentContract',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.dataList = res.data.result.records
          for(let i of res.data.result.records){
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
            let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : ''
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
            i.address = a + b + c
          }
          this.dataList = res.data.result.records
          console.log(this.dataList)
          this.data.total = res.data.result.total
        } else {
          this.dataList = []
          this.data.total = 0
        }
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
        this.isError(err,this)
      })
    },
    searchChange(){//筛选条件改变时
      this.data.params.cursor = 1
      this.getDataList()
    },
    dateChange(){//日期改变时查询
      if(this.data.time == null) {
        this.data.params.jsrcBeginTime = ''
        this.data.params.jsrcEndTime = ''
        this.data.params.cursor = 1
        this.getDataList()
      }else{
        this.data.params.jsrcBeginTime = `${this.data.time[0]} 00:00:00`
        this.data.params.jsrcEndTime = `${this.data.time[1]} 00:00:00`
        this.data.params.cursor = 1
        this.getDataList()
      }
    },
    handleCurrentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    handleSizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    currentChange(row){
      this.rowData = row
    },
  //订单详情
    nextOrPrev(row) {
      this.$refs.highLightRow.setCurrentRow(row);
    },
    orderDetailClose() {
      this.getDataList()
      this.orderDetailDialog = false
    },
  },
}
</script>
<style lang='scss' scoped>
  .screenInput{
    width: 125px;
  }
  .dialog >>> .el-dialog__body{
    height: 486px;
    overflow: auto;
  }
  p{
    margin: 0;
    padding-left: 10px;
    font-size: 16px;
  }
</style>
